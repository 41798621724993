var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// Animated home intro
// Function for 'in-view' class assignment
function inView(element) {
  const $element = $(element);
  $(window).on("load resize scroll", function () {
    if ($element.isInViewport()) {
      $element.addClass("in-view");
    }
  });
}

$(".homeIntro").each(function () {
  inView(this);
});

// Resize header items
function resizeHeaderItems() {
  let height = $(".socialIcons a:first-child").css("height");
  $(
    ".headerContent #siteSearch, .headerContent .searchContainer button, .headerContent .cta-button"
  ).css("height", height);
}

// Homepage functions
if ($("body").hasClass("homepage")) {
  // Reposition feed into to a section at the end
  $(".homeFeed").each(function () {
    var $thisIntro = $(this).find(".feedsTitle ~ *:not(.feedList)");
    $thisIntro
      .insertAfter($(this).find(".feedItem:last-of-type"))
      .wrapAll('<li class="feedItem feedSeeMore"></li>');
    var thisFeed = $(this).find(".feedSeeMore");
    var seeMoreURL = thisFeed.find(".cta-button").attr("href");
    thisFeed.attr("onclick", `window.location="${seeMoreURL}"`);
  });
}

// Document ready functions
$(document).ready(function () {
  resizeHeaderItems();
  $(".feedSeeMore").css("height", $(".feedSeeMore").css("width"));

  // Case studies click behaviour
  $(".CasestudiesFeed .feedItem").each(function () {
    let onclick = $(this).attr("onclick");
    $(this).removeAttr("onclick");
    $(this).click(function () {
      setTimeout(() => {
        $(this).find(".feedItemDetailsWrapper").attr("onclick", onclick);
      }, 200);
    });
    $(this).hover(function () {
      setTimeout(() => {
        $(this).find(".feedItemDetailsWrapper").attr("onclick", onclick);
      }, 200);
    });
  });
});

// Window resize functions
$(window).resize(function () {
  resizeHeaderItems();
  $(".feedSeeMore").css("height", $(".feedSeeMore").css("width"));
});

// Add class to related posts so they can use card prefab
if ($(".associatedRelatedPosts .associatedListing li")) {
  $(".associatedRelatedPosts .associatedListing li").each(function () {
    $(this).addClass("listedPost");
    $(this).find("h3, p").wrapAll('<div class="listedPostText">');
    let thisURL = $(this).find("h3 a").attr("href");
    $(this).attr("onclick", `window.location = "${thisURL}"`);
    $(this)
      .find("h3")
      .replaceWith(function () {
        return "<h2>" + $(this).text() + "</h2>";
      });
  });
}

// Home feature "hover" effects on mobile
$(window).scroll(function () {
  $(".homeFeature").each(function () {
    let featureOffset = $(this).offset().top - $(window).scrollTop();
    if (featureOffset > -150) {
      $(this).addClass("currently-in-view");
    }
    if (featureOffset > 300 || featureOffset < -150) {
      $(this).removeClass("currently-in-view");
    }
  });
});

// Add class to donate nav item
$('a[role="menuitem"][href*="donate"]').parent().addClass('donateNavItem');

// CR-378

if($("body.donate").length) {
  $(window).scroll(function() {
    
  });
} else {
  $(document).on("wheel", "input[type=number]", function (e) {
    $(this).blur();
  });
}
  



